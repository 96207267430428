import React, { useEffect } from 'react';
import Image from './images';
import imageOne from '../images/1.jpg';
import imageThree from '../images/3.jpg';
import imageFour from '../images/4.jpg';
import imageFive from '../images/5.jpg';
import imageSix from '../images/6.jpg';
import imageSeven from '../images/7.jpg';
import imageEight from '../images/8.jpg';
import imageNine from '../images/9.jpg';
import imageTen from '../images/10.jpg';
import imageEleven from '../images/11.JPG';
import imageThirteen from '../images/13.JPG';
import imageFourteen from '../images/14.JPG';

const Gallery = () => {
  useEffect(() => {
    const scrollContainer = document.querySelector('.gallery');

    scrollContainer.addEventListener('wheel', (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });
  });
  return (
    <>
      <div className="gallery" id="gallery">
        <Image img={imageSix} cn="portrait" loading="lazy" alt="" />
        <Image img={imageNine} cn="portrait" loading="lazy" alt="" />
        <Image img={imageThree} cn="portrait" loading="lazy" alt="" />
        <Image img={imageSeven} cn="portrait" loading="lazy" alt="" />
        <Image img={imageTen} cn="portrait" loading="lazy" alt="" />
        <Image img={imageEleven} cn="portrait" loading="lazy" alt="" />
        <Image img={imageFive} cn="portrait" loading="lazy" alt="" />
        <Image img={imageEight} cn="portrait" loading="lazy" alt="" />
        <Image img={imageOne} cn="portrait" loading="lazy" alt="" />
        <Image img={imageFour} cn="portrait" loading="lazy" alt="" />
        <Image img={imageFourteen} cn="portrait" loading="lazy" alt="" />
        <Image img={imageThirteen} cn="portrait" loading="lazy" alt="" />
      </div>
    </>
  );
};

export default Gallery;
