import React from 'react';
import ImageFadeIn from 'react-image-fade-in';

const images = (props) => {
  return (
    <div className="image">
      <ImageFadeIn
        src={props.img}
        className={props.cn}
        loading={props.loading}
        alt={props.alt}
      />
    </div>
  );
};

export default images;
