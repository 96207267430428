import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Preloader from '../loader';

const About = () => {
  const ref = useRef(null);
  const [preloader, setPreload] = useState(true);

  //preloader
  useEffect(() => {
    if (!preloader && ref) {
      if (typeof window === 'undefined' || !window.document) {
        return;
      }
    }
  }, [preloader]);

  const [timer, setTimer] = useState(2);

  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
    setPreload(false);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1300);
    return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  if (typeof window === 'undefined' || !window.document) {
    return null;
  }
  return (
    <div>
      {preloader ? (
        <Preloader />
      ) : (
        <div className="about">
          <div className="header">
            <Link style={{ textDecoration: 'none' }} to="/">
              <h1>VE</h1>
            </Link>
            <div className="nav">
              <ul>
                <Link to="/about-us" className="link">
                  About
                </Link>
                <Link to="archive" className="link">
                  Archive
                </Link>
              </ul>
            </div>
          </div>
          <div className="line" />
          <div className="parag">
            <h3>Company</h3>
            <p>
              VE media is a media company and LLC. We use photography to
              investigate creative concepts and make them concrete.
              <br />
              <br /> High fashion, Portraits, Editorial, Runways, and Events.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
