import React from 'react';
import styled from 'styled-components';

const Loader = () => {
  return (
    <Load className="loader" id="logo">
      <h1>VE</h1>
    </Load>
  );
};

export default Loader;

const Load = styled.div`
  h1 {
    font-family: Voyage;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 5em;
    background-color: #000;
    color: #fff;
  }
`;
